import React from 'react'
import { IconPOA } from '../IconPOA'
import { IconTelegram } from '../IconTelegram'
import { IconTwitter } from '../IconTwitter'

const getIconBackgroundColor = networkBranch => {
  return (
    {
      dai: '#e3e7e9',
      poa: '#fff',
      sokol: '#fff',
      kovan: '#fff'
    }[networkBranch] || '#fff'
  )
}

const getIconColor = networkBranch => {
  return (
    {
      dai: '#333',
      poa: '#5c34a2',
      sokol: '#6ac9b9',
      kovan: '#6ac9b9',
      nrk: '#FEC726'
    }[networkBranch] || '#FEC726'
  )
}

export const SocialIcons = ({ extraClass = '', networkBranch = '' }) => {
  const backgroundColor = '#ffffff'
  const iconColor = '#424A92'

  return (
    <div className={`ft-SocialIcons ${extraClass}`}>
      <IconTwitter backgroundColor={backgroundColor} color={iconColor} text="NRK Twitter" url="https://twitter.com/" />
      <IconTelegram backgroundColor={backgroundColor} color={iconColor} text="NRK Telegram" url="https://t.me/" />
      <IconPOA text="NRK Network" url="https://nordekscan.com/" backgroundColor={backgroundColor} color={iconColor} />
    </div>
  )
}
